@import "./theme.scss";
$max-col: 12;
$max-width: 100;
$ut: "px";

:root {
  --mid-gray: #848484;
  --white-color: #ffffff;
  --success: #8dc63f;
  --warning: #e24301;
  --danger: #e24301;
  --caution: #fbb03b;
  --info: #0093dd;
  --gray: #444;
  --black: #000000;
  --text: rgba(0, 0, 0, 0.87);
  --line: rgba(0, 0, 0, 0.12);
  --menu: #3c3c3c;
  --light-grey: #999999;
  --hover-grey: #ebeced;
  --lighter-grey: #ccc;
  --active-color: #02C18D;
  --font-family: "lato", Arial, sans-serif;
  --mid-light-grey: #666666;
  --text-lato: #333333;
  --table-even: #f8f9fa;
  --header-text: #333333;
  --recommended-version: #18A762;
  --gray-85: #d9d9d9;
  --gray95: #f2f2f2;
  --ghost-white: #F9F9F9; 
  --silver-grey: #cbcbcb;
  --trace: #e48a63;
  --green: #01B30C;
}

.fs-global-topbar__toolbar__listitem--img {
  display: none;
}
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
* {
  box-sizing: border-box;
}
// creating grid  and flex css

/* .row class for creating row */
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  > [class^="col-"] {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
  }
  .row > [class^="col-"] {
      padding-right: 0px;
      padding-left: 0px;
  }
}
@for $col from 1 through $max-col {
  $equation: calc(100% / $max-col) * $col;
  /* .col-{breakpoint}-{column}  for creat a column */
  .col-#{$col} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 $equation;
      flex: 0 0 $equation;
      max-width: $equation;
  }
}
@each $breakpoint, $screen in $breakpoints {
  @media screen and (min-width: $screen) {
      /* .flex-{breakpoint}-row  for to set a horizontal direction */

      .flex-#{$breakpoint}-row {
          flex-direction: row !important;
      }
      /* .flex-{breakpoint}-column  for to set a vertical direction */
      .flex-#{$breakpoint}-column {
          flex-direction: column !important;
      }
      /* .flex-{breakpoint}-row-reverse  for to set a horizontal direction from opposite side */
      .flex-#{$breakpoint}-row-reverse {
          flex-direction: row-reverse !important;
      }
      /* .flex-{breakpoint}-column-reverse  for to set a vertical direction from opposite side */
      .flex-#{$breakpoint}-column-reverse {
          flex-direction: column-reverse !important;
      }
      /* .flex-{breakpoint}-wrap for to set a wrap in flex container */
      .flex-#{$breakpoint}-wrap {
          flex-wrap: wrap !important;
      }
      /* .flex-{breakpoint}-nowrap for to remove wrap in flex container */
      .flex-#{$breakpoint}-nowrap {
          flex-wrap: nowrap !important;
      }
      /* .flex-{breakpoint}-wrap-reverse for to set a wrap in flex container from opposite direction */
      .flex-#{$breakpoint}-wrap-reverse {
          flex-wrap: wrap-reverse !important;
      }
      /* .justify-content-{breakpoint}-start for to align flex items on the main axis from start */
      .justify-content-#{$breakpoint}-start {
          justify-content: flex-start !important;
      }
      /* .justify-content-{breakpoint}-end for to align flex items on the main axis from end */
      .justify-content-#{$breakpoint}-end {
          justify-content: flex-end !important;
      }
      /* .justify-content-{breakpoint}-center for to align flex items on the main axis from center */
      .justify-content-#{$breakpoint}-center {
          justify-content: center !important;
      }
      /* .justify-content-{breakpoint}-between for to align flex items on the main axis between */
      .justify-content-#{$breakpoint}-between {
          justify-content: space-between !important;
      }
      /* .justify-content-{breakpoint}-around for to align flex items on the main axis around */
      .justify-content-#{$breakpoint}-around {
          justify-content: space-around !important;
      }
      /* .align-items-{breakpoint}-start for to align flex items on the cross axis from start */
      .align-items-#{$breakpoint}-start {
          align-items: flex-start !important;
      }
      /* .align-items-{breakpoint}-end for to align flex items on the cross axis from end */
      .align-items-#{$breakpoint}-end {
          align-items: flex-end !important;
      }
      /* .align-items-{breakpoint}-center for to align flex items on the cross axis from center */
      .align-items-#{$breakpoint}-center {
          align-items: center !important;
      }
      /* .align-items-{breakpoint}-center for to align flex items on the cross axis from baseline */
      .align-items-#{$breakpoint}-baseline {
          align-items: baseline !important;
      }
      /* .align-items-{breakpoint}-center for to align flex items on the cross axis from stretch */
      .align-items-#{$breakpoint}-stretch {
          align-items: stretch !important;
      }
      /* .align-content-{breakpoint}-start for to align flex items together on the cross axis from start */
      .align-content-#{$breakpoint}-start {
          align-content: flex-start !important;
      }
      /* .align-content-{breakpoint}-end for to align flex items together on the cross axis from end */
      .align-content-#{$breakpoint}-end {
          align-content: flex-end !important;
      }
      /* .align-content-{breakpoint}-center for to align flex items together on the cross axis from center */
      .align-content-#{$breakpoint}-center {
          align-content: center !important;
      }
      /* .align-content-{breakpoint}-between for to align flex items together on the cross axis between */
      .align-content-#{$breakpoint}-between {
          align-content: space-between !important;
      }
      /* .align-content-{breakpoint}-around for to align flex items together on the cross axis around */
      .align-content-#{$breakpoint}-around {
          align-content: space-around !important;
      }
      /* .align-content-{breakpoint}-stretch for to align flex items together on the cross axis stretch */
      .align-content-#{$breakpoint}-stretch {
          align-content: stretch !important;
      }
      /* .align-self-{breakpoint}-auto for to align flex items  individually change their
   alignment on the cross axis auto */
      .align-self-#{$breakpoint}-auto {
          align-self: auto !important;
      }
      /* .align-self-{breakpoint}-start for to align flex items  individually change their
   alignment on the cross axis start */
      .align-self-#{$breakpoint}-start {
          align-self: flex-start !important;
      }
      /* .align-self-{breakpoint}-end for to align flex items  individually change their
   alignment on the cross axis end */
      .align-self-#{$breakpoint}-end {
          align-self: flex-end !important;
      }
      /* .align-self-{breakpoint}-center for to align flex items  individually change their
   alignment on the cross axis center */
      .align-self-#{$breakpoint}-center {
          align-self: center !important;
      }
      /* .align-self-{breakpoint}-baseline for to align flex items  individually change their
   alignment on the cross axis baseline */
      .align-self-#{$breakpoint}-baseline {
          align-self: baseline !important;
      }
      /* .align-self-{breakpoint}-stretch for to align flex items  individually change their
   alignment on the cross axis stretch */
      .align-self-#{$breakpoint}-stretch {
          align-self: stretch !important;
      }
      @for $col from 1 through $max-col {
          $equation: calc(100% / $max-col) * $col;
          /* .col-{breakpoint}-{col} to create a column  */
          .col-#{$breakpoint}-#{$col} {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 $equation;
              flex: 0 0 $equation;
              max-width: $equation;
          }
      }
  }
}

.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
// ends
// creating margin and padding css

@for $width from 0 through $max-width {
  /* .m-{width}-a to provide margin from top and bottom in px  */
  .m-#{$width}-a {
      margin: #{$width}#{$ut} auto !important;
  }
  /* .m-a-{width} to provide margin from left and right in px  */
  .m-a-#{$width} {
      margin: auto #{$width}#{$ut} !important;
  }
  /* .p-t-{width} to provide padding from top in px  */
  .p-t-#{$width} {
      padding-top: #{$width}#{$ut} !important;
  }
  /* .p-{width}-0 to provide padding from top and bottom in px  */
  .p-#{$width}-0 {
      padding: #{$width}#{$ut} 0 !important;
  }
  /* .p-r-{width} to provide padding from right in px  */
  .p-r-#{$width} {
      padding-right: #{$width}#{$ut} !important;
  }
  /* .p-b-{width} to provide padding from bottom in px  */
  .p-b-#{$width} {
      padding-bottom: #{$width}#{$ut} !important;
  }
  /* .p-l-{width} to provide padding from left in px  */
  .p-l-#{$width} {
      padding-left: #{$width}#{$ut} !important;
  }
  /* .m-l-{width} to provide margin from left in px  */
  .m-l-#{$width} {
      margin-left: #{$width}#{$ut} !important;
  }
  /* .m-t-{width} to provide margin from top in px  */
  .m-t-#{$width} {
      margin-top: #{$width}#{$ut} !important;
  }
  /* .m-r-{width} to provide margin from right in px  */
  .m-r-#{$width} {
      margin-right: #{$width}#{$ut} !important;
  }
  /* .m-b-{width} to provide margin from bottom in px  */
  .m-b-#{$width} {
      margin-bottom: #{$width}#{$ut} !important;
  }
}
// ends

//flex css
/* .d-flex to provide flex funtionality */
.d-flex {
  display: flex;
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.justify-content-end {
  justify-content: flex-end !important;
  -ms-flex-pack: end !important;
}
.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.d-flex-wrap {
flex-wrap: wrap !important;
}
// ends

// btn css
// common btn css
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  outline: none;
}
/* .btn for inherit common button styling */
.btn {
  display: inline-block;
  font-weight: 400;
  color: var(--text);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.85em;
  line-height: 1.5;
  border-radius: 0.25rem;
  min-width: 100px;
  font-family: var(--font-family);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
    &:hover:not(:disabled):not(.disabled){
      background-color: rgba(var(--primary-rgb), 0.09) !important;
    }
    &.color-primary {
      color: var(--primary);
    }
}
/* .btn-outline-primary create outline border button styling */
.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
  &:disabled {
      cursor: default;
      color: var(--light-grey);
      border-color: var(--light-grey);
  }
}
/* .btn + .btn provide margin between two buttons */
.btn + .btn {
  margin-left: 10px;
}
.btn-text {
  color: var(--primary) !important;
  &:disabled,
  &.disabled {
      color: var(--light-grey);
      cursor: default;
  }
  &:hover:not(:disabled):not(.disabled) {
    background-color: rgba(var(--primary-rgb), 0.09);
  }
}
// ends

.ngx-json-viewer {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.search-container {
  .mat-mdc-form-field {
      &.search-input {
          width: 240px;
          font-family: 'Lato';
          .mat-mdc-form-field-icon-suffix{
              i {
                  color: var(--primary);
              }
          }
          .mat--mdc-form-field-infix {
            min-width: 300px;
          }
          &.mat-focused {
            opacity: 1;
            .mat-mdc-form-field-ripple {
              background-color: rgba(0, 0, 0, 0.42);
              height: 1px;
            }
          }
      }
  }
}

.log-level-indicator {
  padding: 3px 5px;
  color: var(--white-color) !important;
  font-size: 6px;
  border-radius: 50%;
  vertical-align: middle;
  &.info {
      background-color: var(--info);
  }
  &.error {
      background-color: var(--danger);
  }
  &.warn {
      background-color: var(--caution);
  }
  &.trace {
      background-color: var(--trace);
  }
  &.success {
      background-color: var(--green);
  }
}