html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
  outline: none;
}

body::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar,
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: height 0.15s ease-out;
}
body::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    opacity: 0.1;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
body:hover::-webkit-scrollbar,
.mat-select-panel:hover::-webkit-scrollbar {
    height: 0.6em;
    transition: height 0.25s ease-in;
}
body:hover::-webkit-scrollbar-thumb,
.mat-select-panel:hover::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
body::-webkit-scrollbar-thumb:hover,
.mat-select-panel::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.55);
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.main-container {
  width: 100%;
  height: 100vh;

  .site-sequencer-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .page-header {
    padding: 5px 10px;
  }

  .main-content {
    position: relative;
    overflow-y: hidden;
  }
}

.breadcrumbs-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
}